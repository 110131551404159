.loader {
  width: 38px;
  height: 38px;
  border: 3px solid lightgray;
  border-bottom-color: black;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

iframe {
  display: none;
}
@media (max-width: 830px) {
  .sidebar-div .slide-nav {
    margin-left: -96px;
    z-index: 9999;
  }
  .sidebar-div .slide-nav-1 {
    margin-left: 0px;
  }
  .header-div .dev_btn {
    display: none;
  }
  .header-div .mobile_btn {
    width: 72px;
    align-items: center;
    display: flex;
  }
  .layout__wrapper .outlet_wrapper {
    margin-left: 0;
  }
  .sidebar-div .sidebar {
    /* margin-left: -100px; */
    z-index: 9999;
  }
}
@media (max-width: 600px) {
  .header-div .mobile_btn {
    width: 25px;
    align-items: center;
    display: flex;
  }
  @media (max-width: 600px) {
    .organization-div {
        display: flex;
      flex-direction: column-reverse;
    }
    .organization-div .first-div {
      border-right: unset;
      margin-top: 15px;
    }
    .organization-div .sidebar-list-div{
        margin-top: 15px;
    }
  }
}

.transcript-container {
  padding-left: 50px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 30px;
  max-height: 580px;
  overflow-y: scroll;

}

.assistant-container {
position: relative;
margin-bottom: 25px;
}

.assistant-icon {
  position: absolute;
  left: -37px;
  background: #F47575;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon {
  position: absolute;
  left: -37px;
  background: #9FE05D;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assistant-title {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 15px;
}

.msg {
  color: #000000;
  font-size: 13px;
}

#table-page div nav ul li:first-child, #table-page div nav ul li:last-child  {
display: flex !important;
}

#table-page div nav ul li {
  display: none;
}